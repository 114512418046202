/*
  jimz@dallmo.com
  date : 2021-07-14
  for simple client redirection via http meta data
*/

import * as React from 'react';
import { Helmet } from "react-helmet";

const Redirect = ({ goto_url }) => {

  // prepare the string with url for redirection
  const content_string = '0; URL=' + goto_url ;

  // the meta data has to be wrapped by react-helmet
  return (
    <Helmet>
      <meta http-equiv="Refresh" content={ content_string } />
    </Helmet>
  )//return

}//const Redirect

export default Redirect;
